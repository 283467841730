import React, { lazy, Suspense } from 'react'
import preloaderIcon from 'assets/img/infographics/exchange.gif'
import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
const Header = lazy(() => import('./sections/Header'))
const GiftCard = lazy(() => import('./sections/GiftCard'))
const HalfQuote = lazy(() => import('./sections/HalfQuote'))
const Cta = lazy(() => import('./sections/Cta'))
const FullQuote = lazy(() => import('./sections/FullQuote'))

const PreLoader = () => <div className="content-y-spacer text-center flex justify-center items-center">
    <img src={preloaderIcon} alt="" className="w-48" />
</div>

function Landing() {
    return (
        <div>
            <Navbar />
            <main>
                <Suspense fallback={<PreLoader />}>
                    <Header />
                    <GiftCard />
                    <HalfQuote />
                    <Cta />
                    <FullQuote />
                </Suspense>
            </main>
            <Footer />
        </div>
    )
}

export default Landing
