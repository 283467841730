import React from 'react'

function Footer() {
    return (
        <footer>
            <div className="flex flex-col md:flex-row justify-center md:justify-between px-4 py-8 md:p-8 lg:p-12">
                <div className="max-w-md">
                    <h3 className="text-xl text-brand font-bold mb-2">Boltswap</h3>
                    <p>We provide lightening fast cryptocurrency exchange at your reach</p>
                </div>
                <div>
                    <p className="font-bold mb-4 mt-6 md:mt-0">Contact Us</p>
                    <ul className="flex flex-col lg:flex-row text-sm">
                        <li className="mb-2 lg:mb-0 lg:mr-4">
                            <a href="tel:+2348063438401" target="_blank" rel="noopener noreferrer" className="no-underline hover:text-brand">
                                <i className="fas fa-phone mr-1" />+234 806 343 8401
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/boltswapexchange" target="_blank" rel="noopener noreferrer" className="no-underline hover:text-brand">
                                <i className="fab fa-instagram mr-1" />boltswapexchange
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full bg-gray-200 text-gray-600">
                <div className="flex justify-center items-center text-center py-4 px-4 md:px-0">
                    <p className="text-sm md:text-md">© 2020 Boltswap All Rights Reserved. | Designed and Developed by <a href="https://pecrio.org" target="_blank" rel="noopener noreferrer" className="border-black hover:border-brand border-b-2 text-brand hover:text-black font-bold">PeCrio</a></p>

                </div>
            </div>
        </footer>
    )
}

export default Footer
