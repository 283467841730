import React from "react";
import ReactDOM from "react-dom";
import './assets/css/main.css'
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as serviceWorker from './serviceWorker';
import Landing from "views/Landing";

ReactDOM.render(
  <Landing />,
  document.getElementById("root")
);

serviceWorker.unregister();