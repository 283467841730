import React from 'react'
import logo from 'assets/img/logo-brand.svg'
function Navbar() {
    return (
        <nav className="w-full bg-brand text-white">
            <div className="flex justify-center px-4 lg:px-12 py-4">
                <div className="w-full flex justify-between items-center font-semibold">
                    <div className="flex">
                        <img src={logo} alt="" className="w-8 h-8 p-1 bg-indigo-200 mr-2 rounded-full" />
                        <h3 className="text-xl">Boltswap</h3>
                    </div>
                    <div>
                        <ul className="flex">
                            <li className="text-md">
                                <a href="https://api.whatsapp.com/send?phone=2348063438401" target="_blank" rel="noopener noreferrer">
                                    <button className="rounded py-2 px-4 bg-indigo-200 text-brand hover:opacity-75">
                                        <i className="fas fa-exchange-alt mr-2" />Sell Now
                                    </button>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
